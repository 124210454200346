<template>
  <v-text-field
    :style="searchHeaderStyle"
    :class="['search', { 'search--dark': dark }]"
    dense
    outlined
    hide-details
    prepend-inner-icon="mdi-magnify"
    single-line
    :label="$t('globals.search')"
    :value="search"
    @input="onSearchChange"
    data-test-id="search"
  />
</template>

<script>
export default {
  name: 'AppSearch',
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
    },
    textColor: {
      type: String,
      default: 'primaryFont',
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    fontFamily: {
      type: String,
      default: 'Poppins',
    },
  },
  computed: {
    searchHeaderStyle() {
      return {
        backgroundColor: this.backgroundColor,
        fontFamily: `"${this.fontFamily}", sans-serif`,
        '--text-color': this.textColor,
        color: `${this.textColor} !important`,
      };
    },
  },
  methods: {
    onSearchChange(search) {
      this.$emit('change', search);
    },
  },
};
</script>

<style>
.v-text-field--outlined fieldset {
  color: var(--text-color, #000) !important;
}

.theme--light.v-input input,
.theme--light.v-input textarea {
  color: var(--text-color, #000) !important;
}
</style>
<style scoped lang="scss">
.search {
  border-radius: 12px;
}

::v-deep .v-icon.theme--light,
::v-deep .v-label.theme--light,
::v-deep .v-input__slot.theme--light {
  color: var(--text-color, #000) !important;
}
</style>
