<template>
  <div :style="subMenuStyle">
    <v-list :style="subMenuStyle" class="hidden-md-and-down">
      <v-list-item :to="profileRoute" class="sub-menu">
        <v-list-item-title :style="subMenuStyle" class="sub-menu__item">
          {{ $t('menu.profile') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item to="/transactions">
        <v-list-item-title :style="subMenuStyle" class="sub-menu__item">
          {{ $t('menu.transactions') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item to="/contacts">
        <v-list-item-title :style="subMenuStyle" class="sub-menu__item">
          {{ $t('menu.contacts') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isAgendaEnabled" :to="agendaRoute">
        <v-list-item-title :style="subMenuStyle" class="sub-menu__item">
          {{ $t('menu.agenda') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="hasKiosk" :to="kioskRoute">
        <v-list-item-title :style="subMenuStyle" class="sub-menu__item">
          {{ $t('menu.myKiosk') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="isAdmin" to="/admin">
        <v-list-item-title :style="subMenuStyle" class="sub-menu__item">
          {{ $t('menu.admin') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item :style="subMenuStyle">
        <div class="sub-menu__item">
          <v-btn outlined :color="accentColor" @click.native="disconnect()">
            {{ $t('menu.logout') }}
          </v-btn>
        </div>
      </v-list-item>
    </v-list>

    <v-list-item-group
      :style="subMenuStyle"
      class="hidden-lg-and-up menu-group"
      active-class="text--accent-4"
    >
      <v-list-item :style="subMenuStyle" class="controls" :inactive="true" :disabled="true">
        <user-avatar :avatar="user.avatar" :size="60" />
      </v-list-item>
      <v-list-item :style="subMenuStyle" to="/profile">
        <v-list-item-title>{{ $t('menu.profile') }}</v-list-item-title>
      </v-list-item>
      <v-list-item :style="subMenuStyle" to="/trainings">
        <v-list-item-title>{{ $t('menu.trainings') }}</v-list-item-title>
      </v-list-item>
      <v-list-item :style="subMenuStyle" to="/transactions">
        <v-list-item-title>{{ $t('menu.transactions') }}</v-list-item-title>
      </v-list-item>

      <v-list-item :style="subMenuStyle" to="/contacts">
        <v-list-item-title>{{ $t('menu.contacts') }}</v-list-item-title>
      </v-list-item>

      <v-list-item :style="subMenuStyle" v-if="isAgendaEnabled" :to="agendaRoute">
        <v-list-item-title>{{ $t('menu.agenda') }}</v-list-item-title>
      </v-list-item>

      <v-list-item :style="subMenuStyle" v-if="user.isAdmin" to="/admin">
        <v-list-item-title>{{ $t('menu.admin') }}</v-list-item-title>
      </v-list-item>

      <v-list-item :style="subMenuStyle">
        <div>
          <v-btn outlined :color="accentColor" @click.native="disconnect()">
            {{ $t('menu.logout') }}
          </v-btn>
        </div>
      </v-list-item>
    </v-list-item-group>
  </div>
</template>

<script>
import UserAvatar from '@/components/user-avatar/UserAvatar.vue';

export default {
  name: 'SubMenu',
  props: {
    user: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: () => ({}),
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    accentColor: {
      type: String,
      default: 'primary',
    },
    color: {
      type: String,
      default: 'black',
    },
    exhibitors: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    UserAvatar,
  },
  computed: {
    disabledConferences() {
      return (
        this.event?.ticket?.options?.disableFunctionalities?.includes('conferences') ||
        !this.event?.options?.conferences
      );
    },
    subMenuStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.color,
      };
    },
    disabledDiscussionGroups() {
      return !this.event?.options?.discussionGroups;
    },
    isAgendaEnabled() {
      return (
        this.$route.params.eventId && (!this.disabledConferences || !this.disabledDiscussionGroups)
      );
    },
    profileRoute() {
      if (this.$route.params.eventId) {
        return `/events/${this.$route.params.eventId}/profile`;
      }

      return '/profile';
    },
    agendaRoute() {
      return `/events/${this.event?.id}/agenda`;
    },
    kiosk() {
      return this.exhibitors?.find((e) => e?.users?.find((u) => u.userId === this.user.id)) || {};
    },
    hasKiosk() {
      return !!this.$route.params.eventId && !!this.kiosk?.id;
    },
    kioskRoute() {
      return `/events/${this.event?.id}/my-kiosk`;
    },
  },
  methods: {
    disconnect() {
      this.$emit('disconnect');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.sub-menu {
  display: flex;
  flex-direction: column;

  min-height: auto;

  &__item {
    padding: 1rem;
    width: 100%;
    text-align: center;

    cursor: pointer;
  }
}

.menu-group {
  padding: 1rem 0;
}
</style>
