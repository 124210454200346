<template>
  <a class="pr-4" :href="`mailto:${this.getSupportEmail}`">
    <v-icon :color="iconColor" size="32" title="Support">mdi-help-circle</v-icon>
  </a>
</template>

<script>
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';
import { mapGetters } from 'vuex';

export default {
  name: 'QuestionZendesk',
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    iconColor: {
      type: String,
      default: 'primaryFont',
    },
    lang: {},
  },
  computed: {
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    hasEvent() {
      return !!this.$route.params?.eventId;
    },
    getSupportEmail() {
      if (!this.hasEvent) {
        return this.defaultEmail;
      }

      return this.customUi?.headerSupportEmail ?? this.defaultEmail;
    },
    currentRouteName() {
      return this.$route.name;
    },
    chatHeightWindows() {
      return document.querySelector('[title="Messaging window"]')?.clientHeight;
    },
  },
  data() {
    return {
      iframe: null,
      isSetHeightChatSection: false,
      chatHeight: 0,
      isAdded: false,
      defaultEmail: 'support@umanize.com',
    };
  },
  created() {
    window.addEventListener('resize', this.initImg);
    const chatWindows = document.querySelector('[title="Messaging window"]');
    if (chatWindows) {
      // if (this.isAdded) return;
      const ro = new ResizeObserver((entries) => {
        // eslint-disable-next-line no-restricted-syntax,@typescript-eslint/no-unused-vars
        for (const entry of entries) {
          if (!this.iframe) {
            this.iframe = document.querySelector('[title="Button to launch messaging window"]');
          }
          if (window.innerHeight < 671) {
            this.iframe.style.display = 'none';
            return;
          }
          if (chatWindows?.clientHeight < 100) {
            this.iframe.style.display = 'none';
          } else {
            const chatWindows = document.querySelector('[title="Messaging window"]');

            if (chatWindows?.parentNode?.offsetHeight < 500) {
              this.iframe.style.display = 'none';
            } else {
              this.iframe.style.display = 'unset';
            }
          }
        }
      });
      ro.observe(chatWindows);
      this.isAdded = true;
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.initImg);
  },
  methods: {
    toggleZendesk() {
      window.location.assign('mailto:support@umanize.com');
    },
  },
};
</script>
