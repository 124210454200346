<template>
  <div>
    <div style="z-index: 999" id="header" :style="headerStyle">
      <div class="menu hidden-lg-and-up">
        <div class="menu__logos">
          <a class="logo-wrapper" @click="goToDashboard()" data-test-id="logo-um">
            <img class="menu__logo" :src="logo" alt="logo" />
          </a>
        </div>

        <AppSearchHeader
          v-if="hasEvent && !isAdminRoute && profileCompleted"
          :textColor="textColor"
          :backgroundColor="backgroundColor"
          :fontFamily="fontFamily"
        />

        <NotificationBadge
          :iconColor="textColor"
          :has-global-unread="hasGlobalUnread"
          :clickable="true"
          @open-chat-users="openChatUsers"
        />
      </div>

      <div class="menu hidden-md-and-down">
        <div class="menu__logos">
          <a class="logo-wrapper" @click="goToDashboard()" data-test-id="logo-um">
            <img class="menu__logo" :src="logo" alt="logo" />
          </a>

          <template v-if="hasEvent">
            <a v-if="!isLobby" @click="goToLobby()" class="d-flex align-center">
              <div class="logo-wrapper" v-if="!!eventLogo">
                <img class="menu__logo" :src="eventLogo" alt="logo" />
              </div>

              <div :style="colorStyle" class="logo__name ml-7 truncate-event-name" id="event-name">
                {{ eventName }}
              </div>
            </a>
            <div v-else class="d-flex align-center">
              <div class="logo-wrapper" v-if="!!eventLogo">
                <img class="menu__logo" :src="eventLogo" alt="logo" />
              </div>

              <div :style="colorStyle" class="logo__name ml-7 truncate-event-name" id="event-name">
                {{ eventName }}
              </div>
            </div>
          </template>
        </div>

        <AppSearchHeader
          v-if="hasEvent && !isAdminRoute && profileCompleted"
          :textColor="textColor"
          :backgroundColor="backgroundColor"
          :fontFamily="fontFamily"
        />

        <div v-if="showNotificationCenter && isLoggedIn && hasEvent" class="px-1">
          <NotificationCenter :color="textColor" :hasGlobalUnread="hasGlobalUnread" />
        </div>

        <div
          v-if="isLoggedIn"
          class="controls hidden-md-and-down"
          id="noti-desktop"
          data-test-id="desktop"
        >
          <div>
            <QuestionZendesk :iconColor="textColor" />
          </div>

          <NotificationBadge
            :iconColor="textColor"
            class="controls__chat"
            :has-global-unread="hasGlobalUnread"
            :clickable="true"
            @open-chat-users="openChatUsers"
          />
          <v-menu bottom origin="center center" transition="slide-y-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="controls__langs mr-3">
                <div class="langs" v-bind="attrs" v-on="on">
                  <span>{{ localeLang }} </span>
                </div>

                <v-icon :color="textColor">mdi-menu-down</v-icon>
              </div>
            </template>

            <v-list :style="headerStyle">
              <v-list-item
                :style="headerStyle"
                v-for="lang in langs"
                :key="lang.name"
                @click="changeLang(lang.value)"
              >
                <v-list-item-title>
                  {{ lang.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu bottom origin="center center" transition="slide-y-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="controls" v-bind="attrs" v-on="on">
                {{ firstName }}
                <v-icon :color="textColor">mdi-menu-down</v-icon>
                <user-avatar :avatar="avatar" :size="60" />
              </div>
            </template>

            <sub-menu
              :user="loggedInUser"
              :is-admin="isAdmin"
              :event="getEvent()"
              :color="textColor"
              :backgroundColor="backgroundColor"
              :accentColor="accentColor"
              :exhibitors="exhibitors"
              @disconnect="disconnect"
            />
          </v-menu>
        </div>
      </div>
    </div>

    <v-navigation-drawer class="hidden-lg-and-up" v-model="drawer" absolute temporary>
      <v-list v-if="isLoggedIn" nav>
        <v-list-item-group active-class="text--accent-4">
          <div v-for="item in menu" :key="item.name">
            <v-list-item v-if="item.displayed" :to="item.route">
              <v-list-item-title>
                {{ $t(getTranslationString(item)) }}
              </v-list-item-title>
            </v-list-item>
          </div>
        </v-list-item-group>

        <v-divider />

        <sub-menu
          :user="loggedInUser"
          :is-admin="isAdmin"
          :color="textColor"
          :accentColor="accentColor"
          :backgroundColor="backgroundColor"
          :event="getEvent()"
          @disconnect="disconnect"
        />

        <AppSearchHeader
          v-if="hasEvent && !isAdminRoute && profileCompleted"
          :textColor="textColor"
          :backgroundColor="backgroundColor"
          :fontFamily="fontFamily"
        />
      </v-list>
    </v-navigation-drawer>

    <div class="browser-banner" v-if="isBrowserIE">
      <span class="banner-text">
        {{ $t('menu.ie') }}
        <a href="https://www.google.ca/intl/en_ca/chrome/">
          {{ $t('menu.download-chrome') }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isMobile } from 'mobile-device-detect';

import logoUm from '@/assets/logos/logo_um.png';

import { navigateToDashBoard } from '@/navigation';

import { APP_CHATS_MODULE } from '@/stores/umanize-app/actions/chats/chats.actions';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_EXHIBITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { SHARED_AUTH_MODULE, DISCONNECT } from '@/stores/shared/actions/auth/auth.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { GET_CUSTOM_UI } from '@/stores/agnostic/actions/custom-ui/agnostic-custom-ui.actions';

import NotificationBadge from '@/components/notification-badge/NotificationBadge.vue';
import NotificationCenter from '@/components/notification-center/NotificationCenter.vue';
import QuestionZendesk from '@/components/zendesk/QuestionZendesk.vue';
import SubMenu from '@/components/layout/sub-menu/SubMenu.vue';
import UserAvatar from '@/components/user-avatar/UserAvatar.vue';

import BrowserUtil from '@/helpers/core/browser.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import MenuUtil from '@/helpers/menu/menu.helper';

import AppSearchHeader from './app-search-header/AppSearchHeader.vue';

export default {
  name: 'LayoutHeader',
  data() {
    return {
      showNotificationCenter: true,
      drawer: false,
      hideMenu: false,
      menu: [],
      currentEventId: null,
      exhibitorForEvent: [],
      backgroundColorDefault: '#fff',
      textColorDefault: 'primary',
      fontFamilyDefault: 'Poppins',
      logoDefault: logoUm,
    };
  },
  components: {
    AppSearchHeader,
    QuestionZendesk,
    NotificationCenter,
    NotificationBadge,
    SubMenu,
    UserAvatar,
  },
  computed: {
    ...mapGetters(SHARED_AUTH_MODULE, ['isLoggedIn']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser', 'loggedInUserRoles']),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(ADMIN_EVENT_MODULE, { adminEvent: 'event' }),
    ...mapGetters(APP_CHATS_MODULE, ['hasGlobalUnread']),
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitors']),
    langs() {
      return [
        { text: this.$t('globals.language.english'), value: 'en-US' },
        { text: this.$t('globals.language.french'), value: 'fr-CA' },
      ];
    },
    isBrowserIE() {
      return BrowserUtil.isIE();
    },
    isAdmin() {
      return PermissionsUtil.isAdmin(this.loggedInUserRoles);
    },
    firstName() {
      return this.loggedInUser?.firstName;
    },
    eventLogo() {
      return isMobile ? this.getEvent()?.logoMobile : this.getEvent()?.logoWeb;
    },
    eventName() {
      return this.getEvent()?.name;
    },
    localeLang() {
      return this.langs.find((lang) => lang.value === this.$root.$i18n.locale)?.text === 'English'
        ? 'EN'
        : 'FR';
    },
    hasEvent() {
      return !!this.getEvent() && !!this.$route.params?.eventId;
    },
    colorStyle() {
      return { color: `${this.textColor} !important` };
    },
    headerStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: `${this.textColor} !important`,
        fontFamily: `"${this.fontFamily}", sans-serif`,
      };
    },
    inAdmin() {
      return (
        this.$route.meta.title === 'menu.adminHome' ||
        this.$route.meta.title === 'menu.admin' ||
        this.$route.path.includes('admin')
      );
    },
    backgroundColor() {
      if (!this.hasEvent || !this.customUi?.headerBackgroundColor || this.inAdmin) {
        return this.backgroundColorDefault;
      }
      return this.customUi?.headerBackgroundColor;
    },
    textColor() {
      if (this.isDashboard || this.inAdmin || !this.hasEvent || !this.customUi?.headerTextColor) {
        return 'rgb(45, 45, 45)';
      }

      return this.customUi?.headerTextColor;
    },
    logo() {
      if (
        !this.hasEvent ||
        !this.customUi?.headerBackgroundColor ||
        this.inAdmin ||
        !this.customUi?.headerLogoWeb
      ) {
        return this.logoDefault;
      }

      return this.customUi?.headerLogoWeb;
    },
    fontFamily() {
      if (!this.hasEvent || !this.customUi?.headerFont || this.inAdmin) {
        return this.fontFamilyDefault;
      }
      return this.customUi?.headerFont;
    },
    accentColor() {
      if (!this.hasEvent || !this.customUi?.headerTextColor || this.inAdmin) {
        return this.customUi?.accentColor;
      }
      return this.customUi?.headerTextColor;
    },
    avatar() {
      return this.loggedInUser?.avatar;
    },
    isDashboard() {
      return this.$route.name === 'Dashboard';
    },
    isLobby() {
      return this.$route.name === 'Lobby';
    },
    profileCompleted() {
      return this.event?.ticket?.userProfileCompleted;
    },
    isAdminRoute() {
      return this.$route?.fullPath?.includes('/admin/');
    },
  },
  methods: {
    ...mapActions(APP_CUSTOM_UI_MODULE, [GET_CUSTOM_UI]),
    ...mapActions(SHARED_AUTH_MODULE, [DISCONNECT]),
    disconnect() {
      this[DISCONNECT]().then(() => {
        this.$router.push('/login');
      });
    },
    getEvent() {
      return this.isAdminRoute ? this.adminEvent : this.event;
    },
    openChatUsers() {
      this.$emit('open-chat-users');
    },
    async goToDashboard() {
      await navigateToDashBoard(this.$router, this.$store);
    },
    onSearchChange(search, type) {
      this.search[type] = search;
    },
    goToLobby() {
      if (this.$route.name !== 'Lobby') {
        this.$router.push({
          name: 'Lobby',
          params: {
            eventId: this.getEvent().id,
          },
        });
      }
    },
    changeLang(value) {
      this.$root.$i18n.locale = value;
    },
    getTranslationString(menuItem) {
      return `menu.${menuItem.name}`;
    },
  },
  watch: {
    async $route(to) {
      this.menu = await MenuUtil.getMenu(to);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.browser-banner {
  display: flex;
  align-items: center;
  width: 100%;

  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: #d2322d;
}

.banner-text {
  text-align: center;
  width: 100%;

  a {
    font-weight: 600;
  }
}

#header {
  width: 100%;
  padding: 1rem;
  height: rem(44px);

  display: flex;
  flex-direction: row;
  align-items: center;

  background: var(--v-primaryBackground-base);
  box-shadow: 0px 3px 6px #00000029;

  position: fixed;
  z-index: 5;
}

.primary-icon {
  z-index: 2;
}

.v-menu {
  &__content {
    margin: 1rem 0 !important;
  }
}

.transparent {
  background: transparent;
}
.truncate-event-name {
  @media screen and (max-width: 1900px) {
    max-width: 600px;
  }
  @media screen and (max-width: 1800px) {
    max-width: 500px;
  }
  @media screen and (max-width: 1600px) {
    max-width: 400px;
  }
  @media screen and (max-width: 1400px) {
    max-width: 350px;
  }
  @media screen and (max-width: 1200px) {
    max-width: 250px;
  }
  max-width: 700px;
  width: max-content;
}
.menu {
  width: 100%;
  height: 100%;

  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__logo {
    height: 100%;
  }

  &__logos {
    display: flex;
    align-items: center;
  }

  &__list {
    width: 100%;

    margin: 0 1rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__item {
    max-width: 12rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: $default-font-size;

    text-align: center;
  }
}

.logo {
  &__name {
    font-weight: $bold;
  }
}

.logo-wrapper {
  height: 35px;
}

.controls {
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: $default-font-size;

  &__picture {
    height: 3rem;
    width: 3rem;
    object-fit: cover;

    margin: 0 1rem;

    border-radius: 50%;
  }

  &__langs {
    display: flex;
    align-items: center;
  }

  &__chat {
    margin-right: 1rem;
  }
}

.langs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@include breakpoint(medium) {
  #header {
    height: rem(56px);
    width: 100%;
    margin: auto;

    justify-content: center;
  }

  .logo-wrapper {
    position: relative;
    top: auto;
  }
}
</style>
