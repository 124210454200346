<template>
  <v-menu
    v-model="isOpen"
    v-if="isLoaded"
    :close-on-content-click="false"
    bottom
    origin="center center"
    transition="slide-y-transition"
    offset-y
    :style="searchHeaderStyle"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        :style="searchHeaderStyle"
        class="controls app-search-header__search"
        v-bind="attrs"
        v-on="on"
      >
        <AppSearch
          class="header__search"
          :textColor="textColor"
          :backgroundColor="backgroundColor"
          :fontFamily="fontFamily"
          @change="onSearchChange($event)"
        />
      </div>
    </template>

    <div>
      <v-list :style="searchHeaderStyle">
        <searchExhibitor
          :textColor="textColor"
          :backgroundColor="backgroundColor"
          :event="event"
          :maximumDisplayedElements="maximumDisplayedElements"
          :includeSearchNoSensitive="includesSearchNoSensitive"
          @close="onCloseMenu"
        />

        <searchConferences
          :textColor="textColor"
          :backgroundColor="backgroundColor"
          :event="event"
          :maximumDisplayedElements="maximumDisplayedElements"
          :includeSearchNoSensitive="includesSearchNoSensitive"
          @go-to="goTo"
        />

        <searchDiscussionGroups
          :textColor="textColor"
          :backgroundColor="backgroundColor"
          :event="event"
          :maximumDisplayedElements="maximumDisplayedElements"
          :includeSearchNoSensitive="includesSearchNoSensitive"
          @go-to="goTo"
        />

        <searchContentLibraries
          :textColor="textColor"
          :backgroundColor="backgroundColor"
          :event="event"
          :maximumDisplayedElements="maximumDisplayedElements"
          :contentCategories="contents"
          :includeSearchNoSensitive="includesSearchNoSensitive"
          @close="onCloseMenu"
        />
      </v-list>
    </div>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AppSearch from '@/components/app/app-search/AppSearch.vue';

import {
  APP_MENU_MODULE,
  SET_SELECTED_CONFERENCE_ROOM,
} from '@/stores/umanize-app/actions/menu/menu.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { APP_CONTENT_MODULE } from '@/stores/umanize-app/actions/content/app-content.actions';
import {
  GET_CONTENT_CATEGORIES,
  GET_CONTENT_CATEGORY,
  GET_CONTENT_ITEMS,
} from '@/stores/agnostic/actions/content/agnostic-content.actions';

import SearchConferences from './item/SearchConferences.vue';
import SearchContentLibraries from './item/SearchContentLibraries.vue';
import SearchDiscussionGroups from './item/SearchDiscussionGroups.vue';
import SearchExhibitor from './item/SearchExhibitor.vue';

const MAXIMUM_DISPLAYED_ELEMENTS = 10;

export default {
  name: 'AppSearchHeader',
  components: {
    AppSearch,
    SearchConferences,
    SearchContentLibraries,
    SearchDiscussionGroups,
    SearchExhibitor,
  },
  props: {
    textColor: {
      type: String,
      default: 'primaryFont',
    },
    backgroundColor: {
      type: String,
      default: 'white',
    },
    fontFamily: {
      type: String,
      default: 'Poppins',
    },
  },
  data: () => ({
    now: new Date().toISOString(),
    search: '',
    maximumDisplayedElements: MAXIMUM_DISPLAYED_ELEMENTS,
    contents: [],
    isLoaded: false,
    isOpen: false,
  }),
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser', 'loggedInUserRoles']),
    ...mapGetters(APP_CONTENT_MODULE, ['contentCategories', 'contentItems']),
    searchNoSensitive() {
      return this.search.toLowerCase().replaceAll(' ', '');
    },
    searchHeaderStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: `${this.textColor} !important`,
        fontFamily: `"${this.fontFamily}", sans-serif !important`,
      };
    },
  },
  methods: {
    ...mapActions(APP_CONTENT_MODULE, [
      GET_CONTENT_CATEGORIES,
      GET_CONTENT_ITEMS,
      GET_CONTENT_CATEGORY,
    ]),
    ...mapActions(APP_MENU_MODULE, [SET_SELECTED_CONFERENCE_ROOM]),
    includesSearchNoSensitive(item) {
      return item?.toLowerCase().replaceAll(' ', '').includes(this.searchNoSensitive);
    },
    goTo(id, name, typeId) {
      this.$router.push({
        name,
        params: {
          eventId: this.$route.params.eventId,
          [typeId]: id,
        },
      });
      this.onCloseMenu();
    },
    onSearchChange(search) {
      this.search = search;
    },
    onCloseMenu() {
      this.isOpen = false;
    },
  },
  async created() {
    const { eventId } = this.$route.params;
    await this[GET_CONTENT_CATEGORIES](eventId);

    this.contents = this.contentCategories;
    this.isLoaded = false;

    await this.contentCategories.forEach(async (contentCategory, index) => {
      await this[GET_CONTENT_ITEMS]({ eventId, categoryId: contentCategory.id });
      this.contents[index].items = this.contentItems;
    });

    this.isLoaded = true;
  },
};
</script>

<style lang="scss" scoped>
.app-search-header__search {
  width: 100%;
  margin: 50px;
}

.v-menu {
  background-color: white;
  z-index: 1000;
}
</style>
