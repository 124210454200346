<template>
  <div class="avatar-container">
    <div class="length" v-if="length">+{{ length }}</div>
    <v-img
      class="picture"
      :class="{
        'picture--no-border': noBorder,
        'picture--thin-border': thinBorder,
        'picture--opaque': length,
      }"
      v-if="avatar"
      :height="size"
      :width="size"
      :src="avatar"
      lazy-src="~@assets/images/profile.png"
      aspect-ratio="1"
      :max-height="size"
      :max-width="size"
    />
    <v-img
      v-else
      :class="{ 'picture picture__placeholder': placeholderBackground }"
      :height="size"
      :width="size"
      contain
      src="~@assets/images/profile.png"
      aspect-ratio="1"
      :max-height="size"
      :max-width="size"
    />
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    avatar: {
      type: String,
    },
    size: {
      type: [Number, String],
      default: 40,
    },
    thinBorder: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: 0,
    },
    placeholderBackground: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';
.avatar-container {
  position: relative;
}

.picture {
  border-radius: 50%;
  border: 3px solid var(--v-primaryFont-base);
  background: var(--v-primaryBackground-base);

  &__placeholder {
    border: none;
  }

  &--no-border {
    border: none;
  }

  &--thin-border {
    border: 1px solid var(--v-primaryFont-base);
  }

  &--opaque {
    opacity: 0.6;
  }
}

.length {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: calc(50% - 1rem);
  font-size: $xx-large-font-size;
  z-index: 2;
}
</style>
