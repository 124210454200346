<template>
  <v-footer class="d-flex align-center footer" :style="footerStyle">
    <a
      v-if="!footerLogoContent"
      class="footer__logo"
      href="https://www.umanize.com/"
      target="_blank"
    >
      <p :style="footerStyle" class="ma-0">{{ $t('footer.poweredBy') }}</p>
      <LogoUmanize :width="135" :color="textColor" />
    </a>

    <div
      v-if="footerLogoContent"
      class="ql-editor"
      data-gramm="false"
      spellcheck="false"
      :style="footerStyle"
      v-html="footerLogoContent"
    />

    <p class="footer__copyright" data-test-id="copyright">{{ this.copyright }}</p>
    <div />
  </v-footer>
</template>

<script>
import LogoUmanize from '@/components/logo-umanize/LogoUmanize.vue';
import { APP_CUSTOM_UI_MODULE } from '@/stores/umanize-app/actions/custom-ui/app-custom-ui.actions';
import { mapGetters } from 'vuex';

export default {
  components: { LogoUmanize },
  name: 'LayoutFooter',
  data() {
    return {
      backgroundColorDefault: '#272727',
      textColorDefault: '#808080',
    };
  },
  computed: {
    ...mapGetters(APP_CUSTOM_UI_MODULE, ['customUi']),
    hasEvent() {
      return !!this.$route.params?.eventId;
    },
    footerCopyrightFr() {
      return this.customUi?.footerCopyright?.fr || '';
    },
    footerCopyrightEn() {
      return this.customUi?.footerCopyright?.en || '';
    },
    copyright() {
      if (this.inAdmin || !this.hasEvent || !this.customUi?.footerCopyright) {
        return this.$t('footer.copyright');
      }

      return this.$i18n.locale.toUpperCase().includes('FR')
        ? this.footerCopyrightFr
        : this.footerCopyrightEn;
    },
    footerLogoContent() {
      if (this.inAdmin || !this.hasEvent || !this.customUi?.footerLogoContent) {
        return '';
      }

      return this.$i18n.locale.toUpperCase().includes('FR')
        ? this.customUi?.footerLogoContent?.fr
        : this.customUi?.footerLogoContent?.en;
    },
    inAdmin() {
      return (
        this.$route.meta.title === 'menu.adminHome' ||
        this.$route.meta.title === 'menu.admin' ||
        this.$route.path.includes('admin')
      );
    },
    footerLogoDirection() {
      if (this.inAdmin || !this.hasEvent || this.customUi?.footerLogoPosition !== 'topCenter') {
        return 'row';
      }

      return 'column';
    },
    footerStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
        flexDirection: `${this.footerLogoDirection} !important`,
        justifyContent: 'space-between',
      };
    },
    backgroundColor() {
      if (!this.hasEvent || !this.customUi?.footerBackgroundColor || this.inAdmin) {
        return this.backgroundColorDefault;
      }
      return this.customUi?.footerBackgroundColor;
    },
    textColor() {
      if (!this.hasEvent || !this.customUi?.footerTextColor || this.inAdmin) {
        return this.textColorDefault;
      }
      return this.customUi?.footerTextColor;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.footer {
  padding: 20px 35px;

  height: auto;
  width: 100vw;

  border-top: 1px solid var(--v-primaryFont-base);

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

  & .ql-editor {
    height: fit-content;
    min-height: fit-content;
    overflow: hidden;
    padding: 0;

    ::v-deep .ql-align-center {
      margin: 0;
    }

    ::v-deep a img {
      cursor: pointer !important;
      max-width: 100%;
    }

    ::v-deep img {
      cursor: default !important;
      max-width: 100%;
    }
  }

  &__logo {
    align-items: center;
  }

  &__copyright {
    margin-bottom: 0 !important;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
  }
}

.logo {
  &__text {
    margin-bottom: 5px;
  }
}

@include breakpoint(small) {
  .footer {
    min-height: 8rem;
    z-index: 1;

    & > *:not(:last-child) {
      margin-bottom: 0;
    }
  }
}
</style>
