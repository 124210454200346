<template>
  <v-list-group
    :style="applyTextColor"
    :color="textColor"
    v-if="eventHasItem"
    :key="item"
    no-action
  >
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>
          {{ $t(`menu.${item}`) }} {{ items.length }}
          {{ $t('admin.users.headers.result') }}
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <slot />

    <v-list-item :style="colorStyle" class="font-italic" v-if="items.length === 0">
      {{ $t('admin.users.headers.noResult') }}
    </v-list-item>

    <v-list-item
      :style="colorStyle"
      class="font-italic"
      v-if="items.length > maximumDisplayedElements"
    >
      {{ $t('admin.users.headers.moreResult') }}
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  name: 'AppSearchHeaderItem',
  props: {
    items: {
      type: Array,
      required: true,
    },
    eventHasItem: {
      type: Boolean,
      default: true,
    },
    item: {
      type: String,
    },
    maximumDisplayedElements: {
      type: Number,
    },
    textColor: {
      type: String,
      default: 'primaryFont',
    },
  },
  computed: {
    applyTextColor() {
      return {
        '--text-color': this.textColor,
      };
    },
    colorStyle() {
      return {
        color: `${this.textColor} !important`,
      };
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-item,
::v-deep .v-icon,
::v-deep .v-list-item__title {
  color: var(--text-color, #000) !important;
}
</style>
